footer {
    min-height: 100vh;
    background-color: #000;
    padding: 80px 10%;
    display: grid;
    grid-template-columns: 3fr 5fr;
    gap: 50px;
}

footer .links {
    margin-top: 40px;
}

footer .links a {
    color: #fff;
    font-weight: 600;
    opacity: 0.9;
    border: none;
    text-decoration: none;
    padding: 0;
    text-align: left;
    line-height: 3rem;
    font-size: 20px;
    display: block;
}

footer .links button,
footer .links button:hover {
    color: #fff;
    text-align: left;
    padding: 0;
    font-weight: 600;
    border-radius: 500px;
    text-decoration: none;
    line-height: 3rem;
    border: none;
    display: block;
    font-size: 20px;
    opacity: 0.9;
}

.faq {
    margin: 40px 0;
    opacity: 0.8;
}

.faq p {
    color: white;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 10px;
}

.faq .answer {
    font-weight: 500;
}
.copyright-message {
    font-size: 12px;
    font-weight: 500;
    color: white;
}
.copyright-message img {
    height: 12px;
}

@media screen and (max-width: 768px) {
    footer {
        grid-template-columns: 1fr;
    }
}
