.fullView{
    min-height: calc(100vh - 80px);
    background-color: #000;
}
.fullView .form-container{
    min-height: 410px;
    width: max(300px, min(50%, 500px));
    border: 1px solid white;
    border-radius: 4px;
    background-color: #fff;
}

.signup-form{
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    font-weight: 500;
}

.signup-form h1{
    margin:10px;
    text-align: center;
}
.signup-form p{
    text-align: center;
}
.signup-form label{
    width: calc(100% - 20px);
    margin-left: 10px;
}
.signup-form input {
    padding: 8px 12px;
    width: calc(100% - 20px);
    margin: 10px;
    border: none;
    border-bottom: 1px solid #343434;
}

.signup-form button,.signup-form button:hover{
    padding: 12px 16px;
    background: linear-gradient(90deg,#7a28ff,#983eff);
    color: white;
    width: calc(100% - 20px);
    margin: 10px;
    font-weight: 500;
}


.fileContainer img{
    height: 50px;
}
.fileContainer {
    font-size: 18px;
    margin: 0 10px;
    padding: 12px;
}

.fullView .msg-box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.msg-box .text{
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
}
.country-code{
    flex-basis: 50px;
    width: 50px;
    background-color: #eee;
    color: #343434;
    /* border-top-left-radius: 8px; */
    /* border-bottom-left-radius: 8px; */
    margin: 10px;
    padding: 8px 12px;
}


@media screen and (max-width:768px){
    .fullView{
        padding: 20px 0;
    }
}


@media screen and (max-width:500px){

}
