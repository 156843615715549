.fullHeight {
    min-height: 100vh;
    height: fit-content;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.fullHeight .container {
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    padding: 20px 10%;
    margin: 0;
    max-width: 100%;
    color: #fff;
    backdrop-filter: brightness(0.9);
}
.fullHeight .container .widthBox{
    width: 40%;
}
.fullHeight.containerInvert .widthBox{
    margin-left: auto;
}

.fullHeight.containerInvert,
.fullHeight.containerInvert .container {
    transform: scaleX(-1);
}

.heading-text {
    font-size: 66px;
    font-weight: 700;
}
.para-text {
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0 30px 0;
}
.fullHeight .container button {
    padding: 16px 40px;
    font-size: 22px;
    font-weight: 600;
    background-color: #fff;
    color: #000;
    border-radius: 500px;
}

@media screen and (max-width: 975px) {
    .heading-text {
        font-size: 46px;
    }
    .fullHeight .container{
        padding: 20px 6%;
    }
    .fullHeight .container button {
        font-size: 18px;
    }
    .fullHeight .container .widthBox {
        width: 80vw;
        max-width: 80vw;
    }
}

@media screen and (max-width: 500px) {
    .heading-text {
        font-size: 32px;
    }
    .para-text {
        font-size: 14px;
    }
    .fullHeight .container{
        padding: 20px 16px;
    }
    .fullHeight .container button {
        font-size: 16px;
    }
    .fullHeight .container .widthBox{
       width: 100%;
       max-width: 100vw;
    }
}
