header {
    position: sticky;
    z-index: 10;
    width: 100%;
    left: 0;
    top: 0;
}
.nav {
    position: relative;
    padding: 12px 200px;
    background-color: var(--primary-color);
}

.nav .logo-container {
    color: white;
    font-weight: 600;
    font-size: 1.6rem;
}
.nav .logo-container span {
    font-size: 2.5rem;
}
.navbar {
    font-weight: 600;
    background-color: #000;
    padding: 0;
}
.navbar > * {
    margin: 0 20px;
}

.navbar a {
    text-decoration: none;
    color: white;
    opacity: 0.8;
}
.navbar button {
    color: white;
    border: white 2px solid;
    border-radius: 500px;
    opacity: 1;
    font-weight: 600;
    padding: 12px 16px;
}

.navbar button:hover {
    color: white;
}
.nav .navbar-small {
    top: 85px;
    left: 0;
    z-index: 1;
    background-color: #000;
    width: 100%;
}

@media screen and (max-width: 1278px) {
    .nav {
        padding: 12px 50px;
    }
}
.hamburger {
    width: 50px;
}

.hamburger:before,
.hamburger:after,
.hamburger div {
    background: #fff;
    content: "";
    display: block;
    height: 6px;
    border-radius: 3px;
    margin: 7px 0;
    transition: 0.5s;
}
.active.hamburger:before {
    transform: translateY(12px) rotate(135deg);
}
.active.hamburger:after {
    transform: translateY(-12px) rotate(-135deg);
}
.active.hamburger div {
    transform: scale(0);
}

@media screen and (max-width: 768px) {
    .navbar {
        position: absolute;
        top: -100px;
        z-index: -10;
        width: 100%;
        transition: all 0.5s ease;
        flex-direction: column;
    }
    .navbar > div {
        margin: 20px;
    }
    header .nav {
        display: grid !important;
        grid-template-columns: 1fr 50px;
        padding: 10px 20px;
        height: 85px;
    }
}

@media screen and (max-width: 500px) {
    .nav .logo-container span{
        font-size: 2.2rem;
    }
    .nav .logo-container{
        font-size: 1.4rem;
    }
}
