@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}

:root {
  --primary-color: #000;
}

body {
  background-color: white;
  position: relative;
}
footer {
    min-height: 100vh;
    background-color: #000;
    padding: 80px 10%;
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: 50px;
    gap: 50px;
}

footer .links {
    margin-top: 40px;
}

footer .links a {
    color: #fff;
    font-weight: 600;
    opacity: 0.9;
    border: none;
    text-decoration: none;
    padding: 0;
    text-align: left;
    line-height: 3rem;
    font-size: 20px;
    display: block;
}

footer .links button,
footer .links button:hover {
    color: #fff;
    text-align: left;
    padding: 0;
    font-weight: 600;
    border-radius: 500px;
    text-decoration: none;
    line-height: 3rem;
    border: none;
    display: block;
    font-size: 20px;
    opacity: 0.9;
}

.faq {
    margin: 40px 0;
    opacity: 0.8;
}

.faq p {
    color: white;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 10px;
}

.faq .answer {
    font-weight: 500;
}
.copyright-message {
    font-size: 12px;
    font-weight: 500;
    color: white;
}
.copyright-message img {
    height: 12px;
}

@media screen and (max-width: 768px) {
    footer {
        grid-template-columns: 1fr;
    }
}

header {
    position: -webkit-sticky;
    position: sticky;
    z-index: 10;
    width: 100%;
    left: 0;
    top: 0;
}
.nav {
    position: relative;
    padding: 12px 200px;
    background-color: var(--primary-color);
}

.nav .logo-container {
    color: white;
    font-weight: 600;
    font-size: 1.6rem;
}
.nav .logo-container span {
    font-size: 2.5rem;
}
.navbar {
    font-weight: 600;
    background-color: #000;
    padding: 0;
}
.navbar > * {
    margin: 0 20px;
}

.navbar a {
    text-decoration: none;
    color: white;
    opacity: 0.8;
}
.navbar button {
    color: white;
    border: white 2px solid;
    border-radius: 500px;
    opacity: 1;
    font-weight: 600;
    padding: 12px 16px;
}

.navbar button:hover {
    color: white;
}
.nav .navbar-small {
    top: 85px;
    left: 0;
    z-index: 1;
    background-color: #000;
    width: 100%;
}

@media screen and (max-width: 1278px) {
    .nav {
        padding: 12px 50px;
    }
}
.hamburger {
    width: 50px;
}

.hamburger:before,
.hamburger:after,
.hamburger div {
    background: #fff;
    content: "";
    display: block;
    height: 6px;
    border-radius: 3px;
    margin: 7px 0;
    transition: 0.5s;
}
.active.hamburger:before {
    -webkit-transform: translateY(12px) rotate(135deg);
            transform: translateY(12px) rotate(135deg);
}
.active.hamburger:after {
    -webkit-transform: translateY(-12px) rotate(-135deg);
            transform: translateY(-12px) rotate(-135deg);
}
.active.hamburger div {
    -webkit-transform: scale(0);
            transform: scale(0);
}

@media screen and (max-width: 768px) {
    .navbar {
        position: absolute;
        top: -100px;
        z-index: -10;
        width: 100%;
        transition: all 0.5s ease;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .navbar > div {
        margin: 20px;
    }
    header .nav {
        display: grid !important;
        grid-template-columns: 1fr 50px;
        padding: 10px 20px;
        height: 85px;
    }
}

@media screen and (max-width: 500px) {
    .nav .logo-container span{
        font-size: 2.2rem;
    }
    .nav .logo-container{
        font-size: 1.4rem;
    }
}

.hero{
    min-height: calc(100vh - 85px);
    background-color: var(--primary-color);
}
.hero .container{
    color: white;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 50vh;
    width: max(300px, 70vw);
}
.hero .container h1,.container h2{
    text-align: center;
}
.hero .container h1{
    font-size: 66px;
    line-height: 80px;
    font-weight: 700;
}
.hero .container h2{
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
    line-height: 30px;
}
.hero .upload-button-container{
    margin-top: 40px;
}
.hero .upload-button-container button{
    background-color: #fff;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 500px;
    padding: 12px 56px;
    font-size: 18px;
}

@media screen and (max-width:768px) {
    .hero .container{
        width: max(300px,80vw);
    }
    .hero .container h1{
        font-size: 46px;
        line-height: 50px;
    }
    .hero .container h2{
        font-size: 16px;
    }
}


@media screen and (max-width:500px) {
    .hero .container{
        width: max(300px,90vw);
    }
    .hero .container h1{
        font-size: 32px;
        line-height: 40px;
    }
    .hero .container h2{
        font-size: 14px;
        line-height: 16px;
    }
}
.fullHeight {
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.fullHeight .container {
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
    width: 100%;
    padding: 20px 10%;
    margin: 0;
    max-width: 100%;
    color: #fff;
    -webkit-backdrop-filter: brightness(0.9);
            backdrop-filter: brightness(0.9);
}
.fullHeight .container .widthBox{
    width: 40%;
}
.fullHeight.containerInvert .widthBox{
    margin-left: auto;
}

.fullHeight.containerInvert,
.fullHeight.containerInvert .container {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

.heading-text {
    font-size: 66px;
    font-weight: 700;
}
.para-text {
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0 30px 0;
}
.fullHeight .container button {
    padding: 16px 40px;
    font-size: 22px;
    font-weight: 600;
    background-color: #fff;
    color: #000;
    border-radius: 500px;
}

@media screen and (max-width: 975px) {
    .heading-text {
        font-size: 46px;
    }
    .fullHeight .container{
        padding: 20px 6%;
    }
    .fullHeight .container button {
        font-size: 18px;
    }
    .fullHeight .container .widthBox {
        width: 80vw;
        max-width: 80vw;
    }
}

@media screen and (max-width: 500px) {
    .heading-text {
        font-size: 32px;
    }
    .para-text {
        font-size: 14px;
    }
    .fullHeight .container{
        padding: 20px 16px;
    }
    .fullHeight .container button {
        font-size: 16px;
    }
    .fullHeight .container .widthBox{
       width: 100%;
       max-width: 100vw;
    }
}

.fullView{
    min-height: calc(100vh - 80px);
    background-color: #000;
}
.fullView .form-container{
    min-height: 410px;
    width: max(300px, min(50%, 500px));
    border: 1px solid white;
    border-radius: 4px;
    background-color: #fff;
}

.signup-form{
    border-radius: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    height: 100%;
    font-weight: 500;
}

.signup-form h1{
    margin:10px;
    text-align: center;
}
.signup-form p{
    text-align: center;
}
.signup-form label{
    width: calc(100% - 20px);
    margin-left: 10px;
}
.signup-form input {
    padding: 8px 12px;
    width: calc(100% - 20px);
    margin: 10px;
    border: none;
    border-bottom: 1px solid #343434;
}

.signup-form button,.signup-form button:hover{
    padding: 12px 16px;
    background: linear-gradient(90deg,#7a28ff,#983eff);
    color: white;
    width: calc(100% - 20px);
    margin: 10px;
    font-weight: 500;
}


.fileContainer img{
    height: 50px;
}
.fileContainer {
    font-size: 18px;
    margin: 0 10px;
    padding: 12px;
}

.fullView .msg-box{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}
.msg-box .text{
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
}
.country-code{
    -webkit-flex-basis: 50px;
            flex-basis: 50px;
    width: 50px;
    background-color: #eee;
    color: #343434;
    /* border-top-left-radius: 8px; */
    /* border-bottom-left-radius: 8px; */
    margin: 10px;
    padding: 8px 12px;
}


@media screen and (max-width:768px){
    .fullView{
        padding: 20px 0;
    }
}


@media screen and (max-width:500px){

}

