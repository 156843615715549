.hero{
    min-height: calc(100vh - 85px);
    background-color: var(--primary-color);
}
.hero .container{
    color: white;
    flex-direction: column;
    min-height: 50vh;
    width: max(300px, 70vw);
}
.hero .container h1,.container h2{
    text-align: center;
}
.hero .container h1{
    font-size: 66px;
    line-height: 80px;
    font-weight: 700;
}
.hero .container h2{
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
    line-height: 30px;
}
.hero .upload-button-container{
    margin-top: 40px;
}
.hero .upload-button-container button{
    background-color: #fff;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 500px;
    padding: 12px 56px;
    font-size: 18px;
}

@media screen and (max-width:768px) {
    .hero .container{
        width: max(300px,80vw);
    }
    .hero .container h1{
        font-size: 46px;
        line-height: 50px;
    }
    .hero .container h2{
        font-size: 16px;
    }
}


@media screen and (max-width:500px) {
    .hero .container{
        width: max(300px,90vw);
    }
    .hero .container h1{
        font-size: 32px;
        line-height: 40px;
    }
    .hero .container h2{
        font-size: 14px;
        line-height: 16px;
    }
}